import React from "react"

import Layout from '../../../components/layout'
import SEO from '../../../components/seo'

import {
  formatMarkdown,
  formatOffice,
  formatBrand,
  longformFormater,
  formatVideoField,
} from "../../../utils/previewFormaters"

import {
  contentfulClient,
  getContentfulEntryID,
} from "../../../utils/contentfulClient"

import NewsSingleComponent from "../../../content/news/NewsSingleComponent"

class NewPreview extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null,
    }
    this.entryID = getContentfulEntryID()
  }

  componentDidMount() {
    if (this.entryID) {
      contentfulClient()
        .getEntries({
          "sys.id": this.entryID,
          content_type: `news`,
          include: 10,
          limit: 1,
        })
        .then(entries => this.formatData(entries))
        .catch(err => console.log(err))
    }
  }

  formatData(entries) {
    let entry = entries.items[0].fields
    let data = { news: null }

    if (entry) {
      data.news = {
        title: entry.title,
        slug: entry.slug,
        content: entry.content ? formatMarkdown(entry.content) : null,
        publishDate: entry.publishDate,
        office: entry.office ? formatOffice(entry.office) : null,
        mainImage: entry.mainImage
          ? { resolutions: { src: entry.mainImage.fields.file.url } }
          : null,
        longform: entry.longform ? longformFormater(entry.longform) : null,
        videoPreview: entry.videoPreview
          ? formatVideoField(entry.videoPreview)
          : null,
        relatedWork: null,
      }

      this.setState({
        data: data,
      })
    }
  }

  render() {
    return (
      <Layout>
        <SEO title={`News Preview`} description={`News Preview`} />
        <div style={{ minHeight: `100vh` }}>
          {this.state.data && (
            <NewsSingleComponent data={this.state.data} section={`news`} />
          )}
        </div>
      </Layout>
    )
  }
}

export default NewPreview
